import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextFieldComponent from "../Shared/textField";
import BasicButtons from "Components/Shared/button";
import { request, session } from "Utils";
import { useForm, useToast } from "Utils/hooks";
import { useState } from "react";
import Loading from "Components/Shared/loading";
import Toast from "Components/Shared/toast";

const LoginPage = () => {
  const { getData, submit, values } = useForm(submitForm);
  const { toggleToast, toastMessage } = useToast();
  const [isLoading, setIsloading] = useState(false);

  async function submitForm() {
    try {
      setIsloading(true);
      const { result } = await request.post({
        url: "/auth/login/",
        data: values
      });
      session.set("token", result.access);
      session.set("userID", result.user_id);
      if (result) {
        const { result: data } = await request.get({
          url: `/auth/profile/${result.user_id}/`
        });
        session.set("user", data);
        result.username = data?.username;
      }
      session.set("username", result?.username);
      window.location.replace("/cms");
    } catch (error: any) {
      toggleToast(error.message);
    } finally {
      setIsloading(false);
    }
  }

  return (
    <>
      <Box
        sx={{
          background: "#fff",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Stack spacing={5}>
          <img
            src="/images/logo.png"
            alt="Unaeon logo"
            width={305}
            height={104}
            className="loginLogImage"
          />
          <form onSubmit={submit}>
            <Stack spacing={7}>
              <Stack spacing={3}>
                <TextFieldComponent
                  label="Email or Username"
                  name="username"
                  onChange={getData}
                  required
                />
                <TextFieldComponent
                  type="password"
                  label="Password"
                  name="password"
                  onChange={getData}
                  required
                />
              </Stack>
              <BasicButtons
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  background:
                    "linear-gradient(90.45deg, #FAA43F 0.32%, #FF6813 101.55%)",
                  boxShadow: "0px 4px 11.100000381469727px 2px #00000024"
                }}
              >
                Login
                {isLoading && (
                  <Loading sx={{ color: "#fff", ml: 0.8 }} size={18} />
                )}
              </BasicButtons>
            </Stack>
          </form>
        </Stack>
      </Box>

      {Boolean(toastMessage) && (
        <Toast
          open={Boolean(toastMessage)}
          message={toastMessage}
          onClose={() => toggleToast("")}
        />
      )}
    </>
  );
};

export default LoginPage;
