import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

import ErrorPage from "Components/Shared/error";
import Wrapper from "Components/Wrapper";
import { useState } from "react";
import { useQuery } from "react-query";
import { request, toLocaleDate } from "Utils";
import { useDailog, useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import NoContentFound from "Components/Shared/noContentFound";
import StickyHeadTable from "Components/Shared/table";
import PageLoading from "Components/Shared/pageLoading";
import Action from "./action";

const getProblemReport = async ({ queryKey }: any) => {
  const [, resolved] = queryKey;
  const { result } = await request.get({
    url: `/cms/bugreports/?resolved=${resolved}`,
  });
  return result;
};

const ProblemsReport = () => {
  const { toggleToast, toastMessage } = useToast();
  const { isOpen, closeDialog, openDialog } = useDailog();
  const [values, setValues] = useState({});
  const [resolved, setResolved] = useState(false);

  const handleClick = (value: any) => {
    setValues(value);
    openDialog();
  };

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["problemReport", resolved],
    queryFn: getProblemReport,
    retry: 2,
    // staleTime: 86400000,
  });

  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 50, name: "Subject", key: "subject" },
    { minWidth: 50, name: "Device info", key: "device_info" },
    // { minWidth: 50, name: "Report", key: "report_text" },
    // { minWidth: 50, name: "Resolution", key: "resolution_text" },
    { minWidth: 50, name: "Reported by", key: "user" },
    { minWidth: 50, name: "Reported Date", key: "reported_date_time" },
    { minWidth: 50, name: "Resolution Date", key: "resolved_date_time" },
    { minWidth: 50, name: "Status", key: "resolved" },
  ];
  const safetyReport = data?.map((report: any, index: number) => ({
    index: ++index,
    ...report,
    resolved: report.resolved ? "resolved" : "unresolved",
    user: report?.user?.username,
    reported_date_time: toLocaleDate(report?.reported_date_time),
    resolved_date_time: toLocaleDate(report?.resolved_date_time),
  }));
  if (isLoading) return <PageLoading />;
  if (error) return <ErrorPage error={error} />;
  return (
    <Wrapper>
      <Container maxWidth="lg">
        <Box>
          <Button
            sx={{ mr: 1 }}
            variant={!resolved ? "contained" : "text"}
            onClick={() => {
              setResolved(false);
              refetch();
            }}
          >
            Unresolved
          </Button>

          <Button
            variant={resolved ? "contained" : "text"}
            onClick={() => {
              setResolved(true);
              refetch();
            }}
          >
            Resolved
          </Button>
        </Box>
        {data?.length ? (
          <Box pb={5}>
            {resolved ? (
              <StickyHeadTable data={safetyReport} columns={columns} />
            ) : (
              <StickyHeadTable
                onClick={handleClick}
                data={safetyReport}
                columns={columns}
              />
            )}
          </Box>
        ) : (
          <NoContentFound />
        )}

        {Boolean(toastMessage) && (
          <Toast
            open={Boolean(toastMessage)}
            message={toastMessage}
            onClose={() => toggleToast("")}
          />
        )}
        <Action
          isOpen={isOpen}
          closeDialog={closeDialog}
          toggleToast={toggleToast}
          data={values}
        />
      </Container>
    </Wrapper>
  );
};
export default ProblemsReport;
