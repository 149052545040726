import Box from "@mui/material/Box";
import Links from "./links";
import { SideNavFunc } from "./type";

const SideNav: SideNavFunc = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        width: { md: "14%", lg: "12.5%" },
        background: "#FEF2E3",
        // overflowY: isHovered ? "scroll" : "hidden", // Ensure this is set to "scroll" when hovered
        maxHeight: "100vh",
        height: "100vh",
        transition: "overflow 0.3s ease-in-out",
        scrollbarWidth: "thin", // For Firefox
        WebkitOverflowScrolling: "touch", // For smooth scrolling on touch devices
        "::-webkit-scrollbar": {
          width: "3px",
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: "#eb2e67",
          borderRadius: "4px",
        },
      }}
    >
      <Box sx={{ position: "static" }}>
        <Links />
      </Box>
    </Box>
  );
};

export default SideNav;
