import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Wrapper from "Components/Wrapper";
import GeneralTab from "Components/Shared/tab";
import UnresovedSafetyReport from "./unreslove";
import ResolvedSafetyReport from "./resolved";

const SafetyReport = () => {
  return (
    <Wrapper>
      <Container maxWidth="xl">
        <Box>
          <GeneralTab
            tabSx={{ width: 220 }}
            tab={["Unresolved safety report", "Resolved safety report"]}
            tabPanel={[<UnresovedSafetyReport />, <ResolvedSafetyReport />]}
          />
        </Box>
      </Container>
    </Wrapper>
  );
};
export default SafetyReport;
