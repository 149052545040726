import * as React from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    borderTop: `solid 2px ${theme.palette.primary.main}`,
  },
}));

export interface DialogTitleProps {
  id?: string;
  children?: React.ReactNode;
  onClose: Function;
  open: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "red",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
interface Props extends DialogTitleProps {
  title: string;
}

export default function DialogComponent(props: Props) {
  const { onClose, id, title, children, ...rest } = props;

  return (
    <div>
      <BootstrapDialog fullWidth={true} {...rest}>
        <BootstrapDialogTitle id={id} onClose={onClose} open={props.open}>
          <Typography style={{ fontWeight: 700, fontSize: 20 }}>
            {title}
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>{children}</DialogContent>
      </BootstrapDialog>
    </div>
  );
}
