import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import { useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { request } from "Utils";
import { useState } from "react";
import Progress from "Components/Shared/loading";
import DialogComponent from "Components/Shared/dailog";
import { useMutation } from "react-query";
import { ActionFunc } from "./type";
import Typography from "@mui/material/Typography";
// import Image from "Components/Shared/image";
import TextareaAutosize from "@mui/material/TextareaAutosize";

const Action: ActionFunc = ({
  toggleToast,
  isOpen,
  closeDialog,
  refetch,
  data,
}) => {
  const { getTextArea, values, submit, reset } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false);
  const mutation = useMutation(
    async () => {
      setIsloading(true);
      return await request.post({
        url: `/cms/bugreports/resolve_report/${data.id}/`,
        data: values,
      });
    },
    {
      onSuccess: ({ message }) => {
        reset();
        toggleToast(message);
        refetch && refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: ({ message }) => {
        toggleToast(message);
        setIsloading(false);
      },
    },
  );

  function submitForm() {
    mutation.mutate();
  }

  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title={`Problem report by ${data.user}`}
        maxWidth="sm"
      >
        <form onSubmit={submit}>
          <Stack spacing={2} py={2}>
            {/* <Image alt="" src="/images/safety.jpeg" height={250} width={410} style={{borderRadius: 6}}/> */}
            <Typography variant="h4">{data.subject}</Typography>
            <Typography>{data.report_text}</Typography>
            <Box>
              <Typography variant="h5" mb={2}>
                How to Resolve the Problem{" "}
              </Typography>
              {data.resolved !== "resolved" ? (
                <TextareaAutosize
                  onChange={(e) => getTextArea(e)}
                  id="resolution_text"
                  style={{
                    height: 80,
                    width: "90%",
                    borderColor: "#dbdbdb",
                    borderRadius: 6,
                    padding: "10px 20px",
                  }}
                  placeholder="Send Solution to user"
                />
              ) : (
                <Typography>{data.resolution_text}</Typography>
              )}
            </Box>
            <ActionButton type="submit" variant="contained" size="small">
              Resolve
              {isLoading && (
                <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
              )}
            </ActionButton>
          </Stack>
        </form>
      </DialogComponent>
    </div>
  );
};

export default Action;
