import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      light: "#FD8901",
      main: "#FF8A00",
      dark: "#E55200",
      contrastText: "#fff",
    },
    secondary: {
      light: "#64BC46",
      main: "#64BC46",
      dark: "#245614",
      contrastText: "#F2F2F2",
    },
    accent: {
      main: "#336094",
      contrastText: "#fff",
    },
  },
  typography: {
    h1: {
      fontWeight: 500,
      color: "#333333",
      lineHeight: "35px",
      fontSize: 30,
      "@media (min-width:600px)": {
        lineHeight: "55px",
        fontSize: 50,
      },
    },
    h2: {
      fontWeight: 600,
      color: "#333333",
      lineHeight: "45px",
      fontSize: "40px",
    },
    h3: {
      fontWeight: 600,
      color: "#333333",
      lineHeight: "30px",
      fontSize: 24,
      "@media (min-width:600px)": {
        lineHeight: "40px",
        fontSize: 36,
      },
    },
    h4: {
      fontWeight: 600,
      color: "#353535",
      lineHeight: "30px",
      fontSize: 20,
    },
    h5: {
      fontSize: "18px",
      fontWeight: 500,
      color: "#333333",
    },
    //   h6: {
    //     fontSize: "18px",
    //     fontWeight: 500,
    //     color: "#3b3b3b",
    //     lineHeight: "32px",
    //     maxWidth: 550,
    //   },
    body1: {
      color: "#616161",
      fontWeight: 300,
      lineHeight: "25px",
      fontSize: 16,
      textAlign: "justify",
      "@media (min-width:600px)": {
        lineHeight: "27px",
        fontSize: 18,
      },
    },
    body2: {
      color: "#616161",
      fontWeight: 300,
      lineHeight: "25px",
      fontSize: 16,
      "@media (min-width:600px)": {
        lineHeight: "27px",
        fontSize: 18,
      },
    },
    //   caption: {
    //     color: "#616161",
    //     lineHeight: "1rem",
    //   },
    fontFamily: ["PT Sans", "Lato"].join(","),
  },
});

declare module "@mui/material/styles" {
  interface Palette {
    accent: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    accent?: PaletteOptions["primary"];
  }
}
