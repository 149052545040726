import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import InputField from "Components/Shared/textField";
import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import StickyHeadTable from "Components/Shared/table";
import { useQuery } from "react-query";
import { request } from "Utils";
import Menu from "./menu";
import NoContentFound from "Components/Shared/noContentFound";
import AddConfig from "./add";
import { useDailog, useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import { SupportInt } from "./type";
import { useState } from "react";

const getSupport = async ({ queryKey }: any) => {
  const [, url] = queryKey;
  const { result } = await request.get({ url });
  return result.results ? result.results : result;
};

const SupportGroup = () => {
  const { isOpen, closeDialog, openDialog } = useDailog();
  const { toggleToast, toastMessage } = useToast();

  const [url, setUrl] = useState("/supportgroups/");
  const [search, setSearch] = useState("");
  const [pageSize, SetPageSize] = useState(50);
  const [page, setPage] = useState(1);
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["support", url],
    queryFn: getSupport,
    retry: 2
    // staleTime: 86400000,
  });

  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 100, name: "Name", key: "name" },
    { minWidth: 50, name: "Privacy", key: "privacy" },
    { minWidth: 50, name: "Country", key: "country" },
    { minWidth: 50, name: "Members count", key: "members_count" },
    { minWidth: 50, name: "Ban status", key: "is_ban" },
    { minWidth: 50, name: "Action", key: "action" }
  ];

  const users = data?.map((support: SupportInt, index: number) => ({
    index: ++index,
    ...support,
    is_ban: support.is_ban ? "Ban" : "Not Ban",
    action: (
      <Menu
        refetch={refetch}
        data={support as any}
        toggleToast={toggleToast}
        isSupperAdmin={true}
      />
    )
  }));
  if (isLoading) return <Loading />;
  if (error) return <ErrorPage error={error} />;
  return (
    <>
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            alignItems: "center"
            // justifyContent: "flex-end"
          }}
          mb={4}
        >
          <Typography component="span" fontWeight={700} mr={2}>
            Filter:
          </Typography>

          <InputField
            type="text"
            defaultValue={search}
            label="search text"
            onChange={(e) => setSearch(e.target.value)}
          />
          <InputField
            type="number"
            label="Page"
            defaultValue={page}
            onChange={(e) => setPage(Number(e.target.value))}
            sx={{ width: { xs: "8%" }, mx: 2 }}
          />
          <InputField
            type="number"
            defaultValue={pageSize}
            label="Page size"
            onChange={(e) => SetPageSize(Number(e.target.value))}
            sx={{ width: { xs: "8%" } }}
          />
          <Button
            onClick={() =>
              setUrl(
                search
                  ? `/search/supportgroups/?page=${page}&page_size=${pageSize}&search=${search}`
                  : "/supportgroups/"
              )
            }
            variant="contained"
            sx={{ ml: 2, py: 1.5, fontSize: 18, px: 4 }}
          >
            Search
          </Button>
        </Box>
        <Box textAlign="right" mb={3}>
          <Button variant="contained" onClick={openDialog} sx={{py: 1.5, px: 3}}>
            Add New Support Group
          </Button>
        </Box>
        {users?.length ? (
          <Box pb={5}>
            <StickyHeadTable
              // onClick={handleClick}
              refetch={refetch}
              data={users}
              dataLength={data?.totalCount}
              columns={columns}
            />
          </Box>
        ) : (
          <NoContentFound />
        )}
      </Container>
      <AddConfig
        isOpen={isOpen}
        closeDialog={closeDialog}
        toggleToast={toggleToast}
        refetch={refetch}
      />
      {Boolean(toastMessage) && (
        <Toast
          open={Boolean(toastMessage)}
          message={toastMessage}
          onClose={() => toggleToast("")}
        />
      )}
    </>
  );
};

export default SupportGroup;
