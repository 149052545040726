import * as React from "react";
import Box from "@mui/material/Box";
import Snackbar, { SnackbarProps } from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";

export default function Toast(props: SnackbarProps) {
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={(e) => props.onClose && props.onClose(e, "clickaway")}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <Box sx={{ width: 500 }}>
      <Snackbar
        {...props}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        key="vertical"
        action={action}
      />
    </Box>
  );
}
