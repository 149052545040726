import FavoriteIcon from "@mui/icons-material/Favorite";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CommentIcon from "@mui/icons-material/CommentOutlined";
import { Link } from "react-router-dom";

export default function PostCard({
  photo,
  text,
  number_of_comments,
  number_of_likes,
  id
}: any) {
  return (
    <Card
      elevation={0}
      sx={{ borderRadius: 4, cursor: "pointer", position: "relative", pb: 2 }}
    >
      <Link to={`/cms/post/${id}`}>
        <CardMedia
          component="img"
          alt="green iguana"
          height="180"
          image={photo || "/images/safety.jpeg"}
        />
        <CardContent sx={{ mb: 4 }}>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            lineHeight="25px"
            height={70}
            className="truncated-text"
          >
            {text}
          </Typography>
        </CardContent>
      </Link>
      <Box
        sx={{ display: "flex", position: "absolute", bottom: 20, left: 20 }}
        mt={6}
      >
        <Typography component="span" display="flex" alignItems="center">
          <IconButton size="small">
            <FavoriteIcon fontSize="small" color="primary" />
          </IconButton>
          <Typography variant="caption">{number_of_likes}</Typography>
        </Typography>
        <Typography component="span" display="flex" alignItems="center" ml={1}>
          <IconButton size="small">
            <CommentIcon fontSize="small" htmlColor="#333333" />
          </IconButton>
          <Typography variant="caption">{number_of_comments}</Typography>
        </Typography>
      </Box>
    </Card>
  );
}
