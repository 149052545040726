import Stack from "@mui/material/Stack";
import InputField from "Components/Shared/textField";

import { useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { request } from "Utils";
import { useState } from "react";
import Progress from "Components/Shared/loading";
import DialogComponent from "Components/Shared/dailog";
import { useMutation } from "react-query";
import { ChangePasswordFunc } from "./type";

const ChangePassword: ChangePasswordFunc = ({
  toggleToast,
  isOpen,
  closeDialog,
  refetch,
}) => {
  const { getData, values, submit } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false);

  const mutation = useMutation(async () => {
      setIsloading(true);
      return await request.patch({
        url: "/admin/change-password",
        data: values,
      });
    },
    {
      onSuccess: ({ message }) => {
        toggleToast(message);
        refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: ({message}) => {
        toggleToast(message);
        setIsloading(false);
      },
  });
  function submitForm() {
    mutation.mutate();
  }
  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="Change password"
        maxWidth="xs"
      >
        <form onSubmit={submit}>
          <Stack spacing={4} py={5}>
            <InputField
              type="password"
              label="Current Password"
              name="currentPassword"
              onChange={getData}
              required
            />

            <InputField
              type="password"
              label="New Password"
              name="newPassword"
              onChange={getData}
              required
            />

            <ActionButton type="submit" variant="contained">
              Change password{" "}
              {isLoading && (
                <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
              )}
            </ActionButton>
          </Stack>
        </form>
      </DialogComponent>
    </div>
  );
};

export default ChangePassword;
