import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import Wrapper from "Components/Wrapper";
import NoContentFound from "Components/Shared/noContentFound";
import FabButton from "Components/Shared/fab";
import AddRole from "./add";
import { useDailog, useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import RoleCard from "./card";
import { RoleInt, RolesPageFunc } from "./type";

// const getRoles = async ({ queryKey }: any) => {
//   const [, pageId, limit] = queryKey;
//   const { data } = await request.get({
//     url: `/roles?pageId=${pageId + 1}&limit=${limit}`,
//   });
//   return data;
// };

const RolesPage: RolesPageFunc = () => {
  const { isOpen, closeDialog, openDialog } = useDailog();
  const { toggleToast, toastMessage } = useToast();
  const data = [
    { name: "ADMIN", id: "string" },
    { name: "SUPPER ADMIN", id: "string" },
    { name: "MARKETER", id: "string" },
  ];
  // const { data, error, isLoading, refetch } = useQuery({
  //   queryKey: ["Users", 1, 50],
  //   queryFn: getRoles,
  //   retry: 2,
  //   // staleTime: 86400000,
  // });

  // if(isLoading) return <Loading />
  // if(error) return <ErrorPage error={error} />
  function refetch() {
    console.log("done");
  }
  return (
    <Wrapper>
      <>
        {data.length ? (
          <Box pb={5} sx={{ height: data.length <= 15 ? "79.5vh" : "" }}>
            <Grid container spacing={2}>
              {data?.map((role: RoleInt, index: number) => (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <RoleCard
                    {...role}
                    toggleToast={toggleToast}
                    refetch={refetch}
                    key={index}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <NoContentFound />
        )}
        <AddRole
          refetch={refetch}
          isOpen={isOpen}
          closeDialog={closeDialog}
          toggleToast={toggleToast}
        />

        <FabButton onClick={openDialog} />
        {Boolean(toastMessage) && (
          <Toast
            open={Boolean(toastMessage)}
            message={toastMessage}
            onClose={() => toggleToast("")}
          />
        )}
      </>
    </Wrapper>
  );
};

export default RolesPage;
