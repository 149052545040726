import Stack from "@mui/material/Stack";
import InputField from "Components/Shared/textField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Person2Outlined from "@mui/icons-material/Person2Outlined";
import Box from "@mui/material/Box";

import { useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { request, session } from "Utils";
import { useState } from "react";
import Progress from "Components/Shared/loading";
import DialogComponent from "Components/Shared/dailog";
import { useMutation } from "react-query";
import { AddRoleFunc } from "../Roles/type";

const Add: AddRoleFunc = ({ toggleToast, isOpen, closeDialog, refetch }) => {
  const { getData, values, submit, reset } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false);

  const mutation = useMutation(
    async () => {
      setIsloading(true);
      return await request.post({
        url: "/articles/",
        data: { ...values, creator_username: session.get("username") },
      });
    },
    {
      onSuccess: ({ message }) => {
        reset();
        toggleToast(message);
        refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: ({ message }) => {
        toggleToast(message);
        setIsloading(false);
      },
    },
  );
  function submitForm() {
    mutation.mutate();
  }
  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="Create new article"
        maxWidth="xs"
      >
        <form onSubmit={submit}>
          <Stack spacing={4} py={5}>
            <InputField
              type="text"
              label="Title"
              name="article_title"
              onChange={getData}
              required
            />
            <InputField
              type="text"
              label="Article Text"
              name="article_text"
              onChange={getData}
              required
            />
            <TextareaAutosize
              name="article_content"
              onChange={(e) => (values.article_content = e.target.value)}
              placeholder="Article text goes here."
              maxRows={5}
              style={{ padding: "20px", borderRadius: 5 }}
            />
            <Box
              sx={{
                border: "solid 1px #dbdbdb",
                textAlign: "Center",
                borderRadius: 2,
                py: 3,
                color: "#333333",
              }}
            >
              <Person2Outlined sx={{ fontSize: 100 }} />
            </Box>
            <ActionButton type="submit" variant="contained">
              Create article
              {isLoading && (
                <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
              )}
            </ActionButton>
          </Stack>
        </form>
      </DialogComponent>
    </div>
  );
};
export default Add;
