import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import NoContentFound from "Components/Shared/noContentFound";
import { useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";

// const getAdmin = async ({ queryKey }: any) => {
//   const [, pageId, limit] = queryKey;
//   const { data } = await request.get({
//     url: `/admins?pageId=${pageId}&limit=${limit}`,
//   });
//   return data;
// };

const History = () => {
  // const { isOpen, closeDialog, openDialog } = useDailog();
  const { toggleToast, toastMessage } = useToast();
  // const [pageId, setPageId] = useState(1);
  // const [limit, setLimit] = useState(10);
  // const [values, setValues] = useState({});
  // const { data, error, isLoading, refetch } = useQuery({
  //   queryKey: ["Users", pageId, limit],
  //   queryFn: getAdmin,
  //   retry: 2,
  //   // staleTime: 86400000,
  // });
  // const handleClick = (value:any) => {
  //   setValues(value)
  //   openDialog()
  // }
  // const isSupperAdmin =
  //   session.get("user").role === "SUPER_ADMIN" ? true : false;
  const tempData = [
    {
      id: "",
      fullName: "Liked a post by @testuser",
      email: "31/12/23 - 15:02",
    },
    {
      id: "",
      fullName: "Commented on a post by @testuser",
      email: "31/12/23 - 15:02",
    },
    { id: "", fullName: "Followed @testuser", email: "31/12/23 - 15:02" },
    {
      id: "",
      fullName: "Became friends with @testuser",
      email: "31/12/23 - 15:02",
    },
    {
      id: "",
      fullName: "Joined the Support Group @testsupportgroup",
      email: "31/12/23 - 15:02",
    },
    {
      id: "",
      fullName: "Joined the Community @testcommunity",
      email: "31/12/23 - 15:02",
    },
    {
      id: "",
      fullName: "Liked a post by @testuser",
      email: "31/12/23 - 15:02",
    },
    {
      id: "",
      fullName: "Commented on a post by @testuser",
      email: "31/12/23 - 15:02",
    },
  ];

  // if(isLoading) return <Loading />
  // if(error) return <ErrorPage error={error} />
  return (
    <>
      <Container>
        {tempData?.length ? (
          <Box pb={5}>
            {tempData.map(() => (
              <Box mb={2}>
                <Typography variant="h5" component="p" fontWeight={700}>
                  Strike
                </Typography>
                <Typography variant="body2" component="p" fontWeight={500}>
                  Reason
                </Typography>
                <Typography variant="body1" component="p">
                  The user posted an image that violated our T&Cs
                </Typography>
                <Typography variant="subtitle2" component="p">
                  Given by Han Solo on 31/12/23 @ 13:49
                </Typography>
              </Box>
            ))}
          </Box>
        ) : (
          <NoContentFound />
        )}
      </Container>

      {Boolean(toastMessage) && (
        <Toast
          open={Boolean(toastMessage)}
          message={toastMessage}
          onClose={() => toggleToast("")}
        />
      )}
    </>
  );
};

export default History;
