import Grid from "@mui/material/Grid";
import PostCard from "./postCard";
import NoContentFound from "Components/Shared/noContentFound";
import { request } from "Utils";
import PageLoading from "Components/Shared/pageLoading";
import ErrorPage from "Components/Shared/error";
import { useEffect, useState } from "react";

const Posts = ({ userId }: { userId: string }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<any>();

  const getPosts = async () => {
    try {
      setIsLoading(true);
      const { result } = await request.get({
        url: `/posts/?user_id=${userId}`
      });
      setData(result.results);
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPosts();
  }, []);

  if (isLoading) return <PageLoading />;
  if (error) return <ErrorPage error={error} />;
  return data?.length > 0 ? (
    <Grid container spacing={3} mt={2}>
      {data?.map((post: any) => (
        <Grid item xs={12} md={6} lg={4} xl={3.5}>
          <PostCard {...post} />
        </Grid>
      ))}
    </Grid>
  ) : (
    <NoContentFound />
  );
};

export default Posts;
