import FavoriteIcon from "@mui/icons-material/Favorite";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CommentIcon from "@mui/icons-material/CommentOutlined";
import Wrapper from "Components/Wrapper";
import Menu from "./menu";
import Toast from "Components/Shared/toast";
import { useToast } from "Utils/hooks";

export default function ViewArticle() {
  const data = [1, 2, 3, 4, 5, 6];
  const { toggleToast, toastMessage } = useToast();
  return (
    <Wrapper>
      <Container maxWidth="md">
        <Box sx={{ display: "flex" }} mb={3}>
          <Avatar
            alt="post"
            src="/images/safety.jpeg"
            sx={{ width: 200, height: 180 }}
            variant="rounded"
          />
          <Box ml={3}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h4" color="#333333" mb={1}>
                Example Article Title
              </Typography>
              <Typography
                component="span"
                variant="caption"
                sx={{
                  background: "green",
                  px: 2,
                  py: 0.5,
                  color: "#fff",
                  borderRadius: 3,
                }}
              >
                Depression
              </Typography>
            </Box>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              lineHeight="23px"
              textAlign="justify"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography component="span" variant="caption" color="#c1c1c1">
                7 days ago
              </Typography>
              <Box display="flex">
                <Typography component="span" display="flex" alignItems="center">
                  <IconButton size="small">
                    <FavoriteIcon fontSize="small" color="primary" />
                  </IconButton>
                  <Typography variant="caption">34</Typography>
                </Typography>
                <Typography
                  component="span"
                  display="flex"
                  alignItems="center"
                  ml={1}
                >
                  <IconButton size="small">
                    <CommentIcon fontSize="small" htmlColor="#333333" />
                  </IconButton>
                  <Typography variant="caption">4</Typography>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>{" "}
        <Typography component="p" variant="caption" color="#c1c1c1">
          Created by Kristin Watson
        </Typography>
        <Divider />
        <Box mt={3}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h4">Comments</Typography>
            <Box>
              <Menu
                refetch={() => console.log("hello")}
                toggleToast={toggleToast}
                name=""
                id=""
              />
            </Box>
          </Box>
          {data.map(() => (
            <Box mb={1.5}>
              <Typography variant="caption" style={{ color: "#333333" }}>
                20/09/6567
              </Typography>
              <Typography variant="subtitle2">
                Led do eiusmod tempor incididunt ut labore et dolore magna
                aliqua
              </Typography>
              <Typography variant="caption" style={{ color: "#333333" }}>
                @deborahsanni
              </Typography>{" "}
              <Box sx={{ display: "flex" }} mt={0.5}>
                <Typography component="span" display="flex" alignItems="center">
                  <IconButton size="small">
                    <FavoriteIcon sx={{ fontSize: 12 }} color="primary" />
                  </IconButton>
                  <Typography variant="caption">34</Typography>
                </Typography>
                <Typography
                  component="span"
                  display="flex"
                  alignItems="center"
                  ml={1}
                >
                  <IconButton size="small">
                    <CommentIcon sx={{ fontSize: 12 }} htmlColor="#333333" />
                  </IconButton>
                  <Typography variant="caption">4</Typography>
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
        {Boolean(toastMessage) && (
          <Toast
            open={Boolean(toastMessage)}
            message={toastMessage}
            onClose={() => toggleToast("")}
          />
        )}
      </Container>
    </Wrapper>
  );
}
