import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

export default function ArticleCard() {
  return (
    <Link to="/cms/article/id">
      <Card elevation={0} sx={{ cursor: "pointer", background: "transparent" }}>
        <CardMedia
          component="img"
          alt="green iguana"
          height="200"
          image="/images/safety.jpeg"
          sx={{ borderRadius: 4 }}
        />
        <CardContent sx={{ px: 0 }}>
          <Typography variant="h4" color="#333333" mb={1}>
            Example Article Title
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            lineHeight="23px"
            textAlign="justify"
          >
            I’m Kristin Watson. This is a longer biograph about me and what I do
            for a living, like to do in my spare time etc. You can also find
            information
          </Typography>
        </CardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between" }} pb={2}>
          <Typography component="span" variant="caption" color="#c1c1c1">
            7 days ago
          </Typography>
          <Typography
            component="span"
            variant="caption"
            sx={{
              background: "green",
              px: 2,
              py: 0.5,
              color: "#fff",
              borderRadius: 3,
            }}
          >
            Depression
          </Typography>
        </Box>
      </Card>
    </Link>
  );
}
