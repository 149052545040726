import FavoriteIcon from "@mui/icons-material/Favorite";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import More from "@mui/icons-material/MoreHoriz";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CommentIcon from "@mui/icons-material/CommentOutlined";
import { request } from "Utils";
import { useQuery } from "react-query";
import PageLoading from "Components/Shared/pageLoading";
import ErrorPage from "Components/Shared/error";

const getUserComments = async ({ queryKey }: any) => {
  const [, userId] = queryKey;
  const { result } = await request.get({ url: `/comments/${userId}` });
  return result;
};
export default function UserComments({ userId }: { userId: string }) {
  const { data, error, isLoading } = useQuery({
    queryKey: ["UsersComments", userId],
    queryFn: getUserComments,
    retry: 2,
    // staleTime: 86400000,
  });
  if (isLoading) return <PageLoading />;
  if (error) return <ErrorPage error={error} />;
  return (
    <Container maxWidth="md">
      <Box mt={3} sx={{ maxWidth: 500, width: "100%" }}>
        {data?.map(() => (
          <Box mb={1.5}>
            <Typography variant="caption" style={{ color: "#333333" }}>
              20/09/6567
            </Typography>
            <Typography variant="subtitle2">
              Led do eiusmod tempor incididunt ut labore et dolore magna aliqua
              labore et dolore magna aliqua
            </Typography>
            <Typography variant="caption" style={{ color: "#333333" }}>
              @deborahsanni
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "space-between" }}
              mt={0.5}
            >
              {" "}
              <Box sx={{ display: "flex" }} mt={0.5}>
                <Typography component="span" display="flex" alignItems="center">
                  <IconButton size="small">
                    <FavoriteIcon sx={{ fontSize: 12 }} color="primary" />
                  </IconButton>
                  <Typography variant="caption">34</Typography>
                </Typography>
                <Typography
                  component="span"
                  display="flex"
                  alignItems="center"
                  ml={1}
                >
                  <IconButton size="small">
                    <CommentIcon sx={{ fontSize: 12 }} htmlColor="#333333" />
                  </IconButton>
                  <Typography variant="caption">4</Typography>
                </Typography>
              </Box>
              <IconButton size="small">
                <More sx={{ fontSize: 20 }} htmlColor="#333333" />
              </IconButton>
            </Box>
            <Divider sx={{ mt: 2, mb: 2 }} />
          </Box>
        ))}
      </Box>
    </Container>
  );
}
