import Stack from "@mui/material/Stack";
import InputField from "Components/Shared/textField";
import Box from "@mui/material/Box";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Close from "@mui/icons-material/Close";
import { useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { useState } from "react";
import Progress from "Components/Shared/loading";
import DialogComponent from "Components/Shared/dailog";
import { useMutation } from "react-query";
import { UpdateFunc } from "./type";
import { request } from "Utils";

const UpdateAdmin: UpdateFunc = ({
  toggleToast,
  isOpen,
  closeDialog,
  data,
  refetch,
}) => {
  const { getData, values, submit, reset } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false);
  const [workingDays, setWorkingDays] = useState(
    data.working_days || [{ day: "", closing: "", opening: "" }],
  );

  const mutation = useMutation(
    async () => {
      setIsloading(true);
      if (values.country) values.country = values.country.toLowerCase();
      return await request.patch({
        url: `/support/emergency_numbers/${data.id}/`,
        data: { ...values, working_days: workingDays },
      });
    },
    {
      onSuccess: ({ message }) => {
        reset();
        toggleToast(message);
        refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: ({ message }) => {
        toggleToast(message);
        setIsloading(false);
      },
    },
  );

  function submitForm() {
    mutation.mutate();
  }

  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="Update Emergency Line details"
        maxWidth="sm"
      >
        <form onSubmit={submit}>
          <Stack spacing={4} py={5}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <InputField
                type="text"
                label="Name"
                name="name"
                defaultValue={data.name}
                onChange={getData}
                sx={{ width: { xs: "100%", sm: "49%" } }}
              />
              <InputField
                type="text"
                label="Email"
                name="email"
                defaultValue={data.email}
                onChange={getData}
                sx={{ width: { xs: "100%", sm: "49%" }, mt: { xs: 4, sm: 0 } }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <InputField
                type="text"
                label="Phone number"
                name="phone_number"
                defaultValue={data.phone_number}
                onChange={getData}
                sx={{ width: { xs: "100%", sm: "49%" } }}
              />
              <InputField
                type="text"
                label="Country"
                name="country"
                defaultValue={data.country}
                onChange={getData}
                sx={{ width: { xs: "100%", sm: "49%" }, mt: { xs: 4, sm: 0 } }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <InputField
                type="text"
                label="Type"
                name="type"
                defaultValue={data.type}
                onChange={getData}
                sx={{ width: { xs: "100%", sm: "49%" } }}
              />
              <InputField
                type="text"
                label="Text number"
                name="text_number"
                defaultValue={data.text_number}
                sx={{ width: { xs: "100%", sm: "49%" }, mt: { xs: 4, sm: 0 } }}
                onChange={getData}
              />
            </Box>
            <InputField
              type="text"
              label="Website"
              name="website"
              defaultValue={data.website}
              onChange={getData}
            />
            <Box>
              <Typography variant="h5" mb={2}>
                working days (kindly add the working days and hour)
              </Typography>
              {workingDays?.map((value, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  mb={2.5}
                >
                  <FormControl
                    sx={{
                      width: { xs: "100%", sm: "30%" },
                      mt: { xs: 4, sm: 0 },
                    }}
                  >
                    <InputLabel id="demo-simple-select-label">Day</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={value.day}
                      label="Day"
                      onChange={(e) => {
                        workingDays[index].day = e.target.value;
                        setWorkingDays([...workingDays]);
                      }}
                    >
                      <MenuItem value="monday">Monday</MenuItem>
                      <MenuItem value="tuesday">Tuesday</MenuItem>
                      <MenuItem value="wednesday">Wednesday</MenuItem>
                      <MenuItem value="thursday">Thursday</MenuItem>
                      <MenuItem value="friday">Friday</MenuItem>
                      <MenuItem value="saturday">Saturday</MenuItem>
                      <MenuItem value="sunday">Sunday</MenuItem>
                    </Select>
                  </FormControl>
                  <InputField
                    type="text"
                    label="Opening"
                    defaultValue={value.opening}
                    onChange={(e) => {
                      workingDays[index].opening = e.target.value;
                      setWorkingDays([...workingDays]);
                    }}
                    sx={{
                      width: { xs: "50%", sm: "30%" },
                      mt: { xs: 4, sm: 0 },
                    }}
                  />
                  <InputField
                    type="text"
                    label="Closing"
                    defaultValue={value.closing}
                    onChange={(e) => {
                      workingDays[index].closing = e.target.value;
                      setWorkingDays([...workingDays]);
                    }}
                    sx={{
                      width: { xs: "50%", sm: "30%" },
                      mt: { xs: 4, sm: 0 },
                    }}
                  />
                  <IconButton
                    onClick={() => {
                      workingDays.splice(index, 1);
                      setWorkingDays([...workingDays]);
                    }}
                  >
                    <Close htmlColor="red" />
                  </IconButton>
                </Box>
              ))}
              <Button
                onClick={() =>
                  setWorkingDays([
                    ...workingDays,
                    { day: "", closing: "", opening: "" },
                  ])
                }
              >
                Add more working day
              </Button>
            </Box>
            <TextareaAutosize
              onChange={(e) => (values.bio = e.target.value)}
              placeholder="Typing bios"
              style={{ borderRadius: 5, padding: "20px" }}
              maxRows={4}
            />
            <ActionButton type="submit" variant="contained">
              Update Emergency Line
              {isLoading && (
                <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
              )}
            </ActionButton>
          </Stack>
        </form>
      </DialogComponent>
    </div>
  );
};

export default UpdateAdmin;
