import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import StickyHeadTable from "Components/Shared/table";
import { useQuery } from "react-query";
import { request } from "Utils";
import Usermenu from "./menu";
import NoContentFound from "Components/Shared/noContentFound";
import Add from "./add";
import { useDailog, useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import { SupportInt } from "./type";

const getAdmin = async () => {
  const { result } = await request.get({
    url: "/support/support_lines/",
  });
  return result;
};

const SupportCommunity = () => {
  const { isOpen, closeDialog, openDialog } = useDailog();
  const { toggleToast, toastMessage } = useToast();
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["supportLines"],
    queryFn: getAdmin,
    retry: 2,
    // staleTime: 86400000,
  });
  // const isSupperAdmin =
  //   session.get("user").role === "SUPER_ADMIN" ? true : false;
  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 150, name: "Name", key: "name" },
    { minWidth: 100, name: "Type", key: "type" },
    { minWidth: 50, name: "Email", key: "email" },
    { minWidth: 50, name: "Number", key: "phone_number" },
    { minWidth: 50, name: "Country", key: "country" },
    { minWidth: 50, name: "Action", key: "action" },
  ];

  const sportLines = data?.map((sportLine: SupportInt, index: number) => ({
    index: ++index,
    ...sportLine,
    action: (
      <Usermenu
        refetch={refetch}
        data={sportLine as any}
        toggleToast={toggleToast}
        isSupperAdmin={true}
      />
    ),
  }));
  if (isLoading) return <Loading />;
  if (error) return <ErrorPage error={error} />;
  return (
    <>
      <Box textAlign="right" mb={3}>
        <Button variant="contained" onClick={openDialog}>
          Add New Support Line
        </Button>
      </Box>
      {sportLines?.length ? (
        <Box pb={5} sx={{ height: sportLines?.length <= 7 ? "77vh" : "" }}>
          <StickyHeadTable
            refetch={refetch}
            data={sportLines}
            dataLength={data?.totalCount}
            columns={columns}
          />
        </Box>
      ) : (
        <NoContentFound />
      )}
      <Add
        isOpen={isOpen}
        closeDialog={closeDialog}
        toggleToast={toggleToast}
        refetch={refetch}
      />
      {Boolean(toastMessage) && (
        <Toast
          open={Boolean(toastMessage)}
          message={toastMessage}
          onClose={() => toggleToast("")}
        />
      )}
    </>
  );
};

export default SupportCommunity;
