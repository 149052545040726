import Stack from "@mui/material/Stack";
import InputField from "Components/Shared/textField";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

import { useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { request } from "Utils";
import { useState } from "react";
import Progress from "Components/Shared/loading";
import DialogComponent from "Components/Shared/dailog";
import { useMutation, useQuery } from "react-query";
import { AddFunc } from "./type";

const getRoles = async () => {
  const { data } = await request.get({ url: "/roles" });
  return data;
};

const Add: AddFunc = ({ toggleToast, isOpen, closeDialog, refetch }) => {
  const { getData, values, submit, reset } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false);
  const { data } = useQuery({ queryKey: ["roles"], queryFn: getRoles });
  const mutation = useMutation(
    async () => {
      setIsloading(true);
      return await request.post({
        url: "/admin",
        data: values,
      });
    },
    {
      onSuccess: ({ message }) => {
        reset();
        toggleToast(message);
        refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: ({ message }) => {
        toggleToast(message);
        setIsloading(false);
      },
    },
  );

  function submitForm() {
    mutation.mutate();
  }

  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="Add new admin"
        maxWidth="xs"
      >
        <form onSubmit={submit}>
          <Stack spacing={4} py={5}>
            <InputField
              type="text"
              label="Name"
              name="fullName"
              onChange={getData}
              required
            />
            <InputField
              type="email"
              label="Email"
              name="email"
              onChange={getData}
              required
            />

            <FormControl fullWidth>
              <InputLabel>Assign Role *</InputLabel>
              <Select
                name="roleId"
                labelId="roleId"
                label="Select Role"
                value={values.roleId}
                onChange={(e: any) => getData(e)}
                required
              >
                {data?.map(
                  ({ name, id }: { name: string; id: any }, index: number) => (
                    <MenuItem key={`${index}-type`} value={id}>
                      {name}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
            <ActionButton type="submit" variant="contained">
              Add Admin{" "}
              {isLoading && (
                <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
              )}
            </ActionButton>
          </Stack>
        </form>
      </DialogComponent>
    </div>
  );
};

export default Add;
